import Catalog from "./components/Catalog/Catalog"
import AdminCatalog from "./pages/AdminCatalog/AdminCatalog"
import Cart from "./pages/Cart/Cart"
import Home from "./pages/Home/Home"
// import Payment from "./pages/Payment/Payment"
import ProductItem from "./components/ProductItem/ProductItem"
import Profile from "./pages/Profile/Profile"
import {
    HOME,
    CATALOG,
    PRODUCT,
    PROFILE,
    CART,
    // PAYMENT,
    ADMIN_CATALOG
} from "./utils/consts"

export const publicRoutes = [
    {
        path: HOME,
        Component: Home
    },
    {
        path: CATALOG,
        Component: Catalog
    },
    {
        path: PRODUCT,
        Component: ProductItem
    },
    {
        path: PROFILE,
        Component: Profile
    },
    {
        path: CART,
        Component: Cart
    },
    // {
    //     path: PAYMENT,
    //     Component: Payment
    // }
]

export const adminRoutes = [
    {
        path: HOME,
        Component: Home
    },
    {
        path: CATALOG,
        Component: Catalog
    },
    {
        path: PRODUCT,
        Component: ProductItem
    },
    {
        path: PROFILE,
        Component: Profile
    },
    {
        path: CART,
        Component: Cart
    },
    // {
    //     path: PAYMENT,
    //     Component: Payment
    // },
    {
        path: ADMIN_CATALOG,
        Component: AdminCatalog
    }
]