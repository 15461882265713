import React, { useEffect, useState } from 'react'
import styles from './Payment.module.css'
import Navbar from '../../components/Navbar/Navbar';
import { sendOrder } from '../../http/userApi';
import { useDispatch, useSelector } from 'react-redux';
import BlockBody from '../../components/BlockBody/BlockBody';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../../reducers/userReducer';
import Loader from '../../components/Loader/Loader';
import FullScreenNotification from '../../components/FullScreenNotification/FullScreenNotification';
import { motion } from "framer-motion";

function Payment({ openPaymentForm, setOpenPaymentForm, cart, form, setForm }) {

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [touched, setTouched] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const navigate = useNavigate();

    BlockBody({ open: openPaymentForm });

    const handleSendOrder = async () => {

        setTouched(true);

        if (form.full_name !== '', form.phone !== '', form.email !== '', form.delivery_method !== '', form.address !== '') {
            setLoading(true);
            await sendOrder(form, cart);
            setLoading(false);

            dispatch(setUser({ ...user, cart: [] }));
            setNotificationVisible(true);
        }

    }

    const handleDeliveryMethodChange = (method) => {
        setForm({ ...form, delivery_method: method });
    }

    const handleClose = () => {
        setOpenPaymentForm(false);
        navigate('/');
    }

    return (
        <section className={styles.payment__section}>

            <Navbar open={openPaymentForm} setOpen={setOpenPaymentForm} />

            <h2 className={styles.title}>Форма оплаты</h2>

            <div className={styles.payment}>

                <form className={styles.form}>

                    <div className={styles.field}>
                        <label className={styles.label}>ФИО*</label>
                        <input className={`${styles.input} ${touched && form.full_name === '' ? styles.error__input : ''}`} value={form.full_name} onChange={(e) => setForm({ ...form, full_name: e.target.value })} type="text" />
                        {touched && form.full_name === '' && <p className={styles.error}>Обязательное поле!</p>}
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>Номер телефона*</label>
                        <input className={`${styles.input} ${touched && form.phone === '' ? styles.error__input : ''}`} value={form.phone} onChange={(e) => setForm({ ...form, phone: e.target.value })} type="text" />
                        {touched && form.phone === '' && <p className={styles.error}>Обязательное поле!</p>}
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>Email*</label>
                        <input className={`${styles.input} ${touched && form.email === '' ? styles.error__input : ''}`} value={form.email} onChange={(e) => setForm({ ...form, email: e.target.value })} type="text" />
                        {touched && form.email === '' && <p className={styles.error}>Обязательное поле!</p>}
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>Способ доставки*</label>
                        <div className={styles.buttons}>
                            <motion.button type="button" whileTap={{ scale: 0.95 }} className={`${form.delivery_method === 'СДЭК' ? styles.activeButton : styles.button}
                             ${touched && form.delivery_method === '' ? styles.error__input : ''}`}
                                onClick={() => handleDeliveryMethodChange('СДЭК')}>СДЭК<br />(+ 400 руб.)</motion.button>
                            <motion.button type="button" whileTap={{ scale: 0.95 }} className={`${form.delivery_method === 'Почта России' ? styles.activeButton : styles.button} 
                            ${touched && form.delivery_method === '' ? styles.error__input : ''}`}
                                onClick={() => handleDeliveryMethodChange('Почта России')}>Почта России<br />(+ 450 руб.)</motion.button>
                        </div>
                        {touched && form.delivery_method === '' && <p className={styles.error}>Обязательное поле!</p>}
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>{form.delivery_method === 'СДЭК' ? 'Адрес пункта выдачи СДЭК:*' : 'Адрес получателя (+индекс):*'}</label>
                        <input className={`${styles.input} ${touched && form.address === '' ? styles.error__input : ''}`} value={form.address} onChange={(e) => setForm({ ...form, address: e.target.value })} type="text" />
                        {touched && form.address === '' && <p className={styles.error}>Обязательное поле!</p>}
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>Комментарий к заказу</label>
                        <textarea className={styles.textarea} value={form.comment} onChange={(e) => setForm({ ...form, comment: e.target.value })} type="text" />
                    </div>

                </form>

                <footer className={styles.footer}>
                    <motion.button className={styles.footer__btn} whileTap={{ scale: 0.95 }} onClick={() => handleSendOrder()}>Оформить заказ</motion.button>
                </footer>

            </div>

            {loading &&
                <div className={styles.loader}>
                    <Loader />
                    <p className={styles.loader__text}>Идет загрузка...</p>
                </div>
            }

            {notificationVisible && (
                <FullScreenNotification
                    message="Заказ успешно оформлен! Ваша заявка будет обработана в ближайшее время."
                    onConfirm={() => handleClose()}
                    confirmText="Хорошо"
                    onlyConfirm={true}
                    onCancel={() => setNotificationVisible(false)}
                />
            )}

        </section>
    )
}

export default Payment