import React, { useEffect, useState } from 'react'
import { getListElements } from '../../http/shopApi';
import styles from './RelatedItems.module.css';
import { IoMdClose } from 'react-icons/io';
import Loader from '../Loader/Loader';
import { FaCheck } from "react-icons/fa";
import { motion } from "framer-motion";
import { fetchRelatedItems } from '../../http/userApi';

function RelatedItems({ openRelated, setOpenRelated }) {
    const [fullLoading, setFullLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);
    const [items, setItems] = useState([]);
    const [selectedList, setSelectedList] = useState([]);

    useEffect(() => {
        getItems();
    }, []);

    const getItems = async () => {
        setIsLoading(true);
        const items = await getListElements(page, limit);
        const data = items.items.filter(item => item?.type === 'dlc');
        setSelectedList(data.map(item => item._id));
        setItems(items.items);
        setIsLoading(false);
    }

    const handleSelectItem = (id) => {
        if (selectedList.includes(id)) {
            setSelectedList(selectedList.filter(itemId => itemId !== id));
        } else {
            setSelectedList([...selectedList, id]);
        }
    }

    const handleSave = async () => {
        setIsLoading(true);
        await fetchRelatedItems(selectedList);
        setIsLoading(false);
        setOpenRelated(false);
    }

    return (
        <section className={styles.related__items}>
            <div className={styles.related__items__container}>
                <h2 className={styles.title}>Сопутствующие</h2>
                <div>
                    <IoMdClose className={styles.close__btn} onClick={() => setOpenRelated(false)} />
                </div>
            </div>

            <div className={styles.related__items__list}>
                {isLoading ?
                    <div className={styles.loader}><Loader /></div> :
                    items.map(item => (
                        <motion.div
                            whileTap={{ scale: 0.95 }}
                            className={styles.related__item}
                            key={item._id}
                            onClick={() => handleSelectItem(item._id)}>
                            <div className={styles.image__container}>
                                <img className={styles.image} src={process.env.REACT_APP_IMAGE_URL + item.image} alt={item.title} />
                                <div className={`${styles.check} ${selectedList.includes(item._id) && styles.dlc}`}>
                                    {selectedList.includes(item._id) && <FaCheck className={styles.check__icon} />}
                                </div>
                            </div>
                            <div className={styles.info}>
                                <p>{item.name}</p>
                            </div>
                        </motion.div>
                    ))}
            </div>

            <footer className={styles.footer}>
                <motion.button type='button' whileTap={{ scale: 0.95 }} className={styles.create__btn} onClick={() => handleSave()} >Сохранить</motion.button>
            </footer>
        </section >
    );
}

export default RelatedItems;
