import React, { useEffect } from 'react';
import { fetchProfile } from './http/userApi';
import { useDispatch } from 'react-redux';
import { setUser } from './reducers/userReducer';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/AppRouter/AppRouter';
import { useTelegram } from './hooks/useTelegram';

function App() {

  const { tg, tg_user } = useTelegram();

  useEffect(() => {
    tg.expand();
    tg.enableClosingConfirmation();
    getUser();
    tg.ready();
  }, [])

  const dispatch = useDispatch();

  const getUser = async () => {
    const data = await fetchProfile(tg_user.id);
    dispatch(setUser(data));
  }

  return (
    <BrowserRouter>
      <div className="App">
        <AppRouter />
      </div>
    </BrowserRouter>
  );
}

export default App;
