import React, { useEffect, useState } from 'react'
import styles from './ProductItem.module.css'
import { getElementById } from '../../http/shopApi';
import Loader from '../Loader/Loader';
import { FiPlus, FiMinus } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { sendToCart } from '../../http/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import BuyAll from '../BuyAll/BuyAll';
import { setUser } from '../../reducers/userReducer';
import { useNavigate, useParams } from 'react-router-dom';
import ChangeInfo from '../ChangeInfo/ChangeInfo';
import { v4 as uuidv4 } from 'uuid';

function ProductItem() {
    const { id } = useParams();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState();
    const [count, setCount] = useState(1);
    const [selectedItems, setSelectedItems] = useState([]);

    const [onePrice, setOnePrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        getListElement();
    }, [id]);

    useEffect(() => {
        const calculateTotalPrice = () => {
            // Начинаем с цены основного товара.
            let sum = parseFloat(item.price);

            // Добавляем цену каждой выбранной опции.
            selectedItems.forEach(el => {
                sum += parseFloat(el.option.price);
            });

            setOnePrice(sum);
            // Общая цена - это сумма (цена товара плюс цены выбранных опций), умноженная на количество.
            let totalPrice = sum * count;
            setTotalPrice(totalPrice);
        };

        if (item && selectedItems) {
            calculateTotalPrice();
        }
    }, [selectedItems, count, item]);


    const getListElement = async () => {
        setLoading(true);
        const data = await getElementById(id);
        setLoading(false);
        setItem(data);
    }

    const addToCart = async () => {
        const order = {
            id: uuidv4(),
            productId: item._id,
            image: item.image,
            name: item.name,
            price: onePrice,
            count: count,
            options: selectedItems
        };

        dispatch(setUser({ ...user, cart: [...user.cart, order] }));

        setLoading(true);
        await sendToCart(user.id, order);
        setLoading(false);
        navigate('/');
    }


    const handleNavigate = (el) => {
        setItem(null);
        setSelectedItems([]);
        setCount(1);
        setTotalPrice(0);
        navigate(`/product/${el._id}`);
    }

    if (!item) {
        return (
            <div className={styles.loader}>
                <Loader />
            </div>
        )
    }

    return (
        <article className={styles.item}>

            <IoMdClose className={styles.item__close} onClick={() => navigate(-1)} />

            <figure className={styles.img__wrapper}>
                <img className={styles.item__img} src={process.env.REACT_APP_IMAGE_URL + item.image} alt={''} />
            </figure>

            <div className={styles.item__info}>

                <div className={`${styles.item__text} ${item.functions.length === 0 && styles.item__text__none__border}`}>
                    <h2 className={styles.item__name}>{item.name}</h2>
                    <p className={styles.item__price}>Цена: {item.price} руб.</p>
                    <p className={styles.item__description}>{item.description}</p>
                </div>

                {item.functions.length > 0 ? <BuyAll
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    item={item} /> : user.role === 'admin' && <p>Нет доступных функций!</p>}

                {user.role === 'admin' && (
                    <ChangeInfo item={item} />
                )}

                <div className={`${styles.related} ${item.functions.length === 0 && styles.related__none__border}`}>
                    <h3 className={styles.related__item__title}>Сопутствующие товары</h3>

                    <ul className={`${styles.related__list} ${item.related_products?.length === 1 && item.related_products[0]._id === id && styles.related__none} ${item.related_products?.length === 0 && styles.related__none}`}>
                        <>
                            {item.related_products?.map((el, index) => (
                                el._id !== id && (
                                    <li key={el._id} className={styles.related__item} onClick={() => handleNavigate(el)}>
                                        <figure className={styles.related__img__wrapper}>
                                            {el.count === 0 ? <span className={styles.item__out}>Нет в наличии!</span> :
                                                <img className={styles.related__item__img} src={process.env.REACT_APP_IMAGE_URL + el.image} alt={''} />}
                                        </figure>

                                        <div className={styles.related__item__info}>
                                            <p className={styles.related__item__name}>{el.name}</p>
                                            <div className={styles.related__item__btns}>
                                                <button className={`${styles.related__item__btn} ${el.count === 0 && styles.count__out}`}>Купить за {el.price} {window.innerWidth > 380 ? '₽' : '₽'}</button>
                                            </div>
                                        </div>
                                    </li>
                                )
                            ))}
                            {item.related_products?.length === 0 && <p className={styles.related__item__none}>Пусто</p>}
                            {item.related_products?.length === 1 && item.related_products[0]._id === id && <p className={styles.related__item__none}>Пусто</p>}
                        </>
                    </ul>
                </div>

            </div>

            <div className={styles.item__counter}>

                <div className={styles.counter}>
                    <div
                        className={styles.counter__icon}
                        onClick={() => count > 1 && setCount(count - 1)}>
                        <FiMinus />
                    </div>
                    <p className={styles.counter__count}>
                        {count}
                    </p>
                    <div
                        className={`${styles.counter__icon} ${count === item.count && styles.counter__icon__none}`}
                        onClick={() => count < item.count && setCount(count + 1)}>
                        <FiPlus />
                    </div>
                </div>

                <div className={styles.item__btns}>
                    <motion.button
                        className={styles.item__btn}
                        onClick={addToCart}
                        whileTap={{ scale: 0.95 }}
                    >
                        Добавить
                        <div className={styles.item__price}>
                            <span className={styles.item__sum}>{totalPrice.toFixed(2)} ₽</span>
                        </div>
                    </motion.button>

                </div>
            </div>

            {loading &&
                <div className={styles.loader}>
                    <Loader />
                </div>}
        </article >
    )
}

export default ProductItem