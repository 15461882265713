import React, { useState } from 'react';
import styles from './ListManager.module.css';
import { IoMdClose } from "react-icons/io";
import { v4 as uuidv4 } from 'uuid';
import { motion } from "framer-motion";

const ListManager = ({ lists, setLists, change = false }) => {

    const handleAddList = () => {
        setLists(prev => [...prev, { id: uuidv4(), name: '', items: [] }]);
    };

    const handleAddItemToList = (listId) => {
        setLists(prev =>
            prev.map(list =>
                list.id === listId ? { ...list, items: [...list.items, { id: uuidv4(), name: '', price: '' }] } : list
            )
        );
    };

    const handleRemoveList = (listId) => {
        setLists(prev => prev.filter(list => list.id !== listId));
    };

    const handleRemoveItem = (listId, itemId) => {
        setLists(prev =>
            prev.map(list =>
                list.id === listId ? { ...list, items: list.items.filter(item => item.id !== itemId) } : list
            )
        );
    };

    const handleChange = (listId, itemId, field, value) => {
        setLists(prev =>
            prev.map(list =>
                list.id === listId
                    ? {
                        ...list,
                        items: itemId
                            ? list.items.map(item => (item.id === itemId ? { ...item, [field]: value } : item))
                            : list.items,
                        name: itemId ? list.name : value,
                    }
                    : list
            )
        );
    };

    return (
        <div>
            <div className={`${styles.header} ${change && styles.change__header}`}>
                <label>Параметры</label>
                <motion.button type='button'
                    className={styles.add__btn}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleAddList}>
                    Добавить параметр
                </motion.button>
            </div>
            {lists.map(list => (
                <div key={list.id} className={`${styles.form} ${change && styles.change__form}`}>
                    {lists.length > 0 && <h4 className={styles.subtitle}>Название списка</h4>}
                    <div className={styles.field}>
                        <input
                            className={styles.input}
                            value={list.name}
                            onChange={e => handleChange(list.id, null, 'name', e.target.value)}
                            placeholder="Название списка"
                        />

                        <motion.div
                            whileTap={{ scale: 0.95 }}>
                            <IoMdClose className={styles.delete} onClick={() => handleRemoveList(list.id)} />
                        </motion.div>

                    </div>

                    {list.items.length > 0 && <h4 className={styles.subtitle}>Элементы внутри списка</h4>}
                    {list.items.map(item => (
                        <div key={item.id} className={styles.field}>
                            <div className={styles.inputs}>
                                <input
                                    className={styles.input}
                                    value={item.name}
                                    onChange={e => handleChange(list.id, item.id, 'name', e.target.value)}
                                    placeholder="Название элемента"
                                />
                                <input
                                    className={styles.input}
                                    type="number"
                                    value={item.price}
                                    onChange={e => handleChange(list.id, item.id, 'price', e.target.value)}
                                    placeholder="Цена"
                                />
                            </div>

                            <motion.div
                                whileTap={{ scale: 0.95 }}>
                                <IoMdClose className={styles.delete} onClick={() => handleRemoveItem(list.id, item.id)} />
                            </motion.div>

                        </div>

                    ))}
                    <motion.button type='button'
                        className={styles.add__element}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => handleAddItemToList(list.id)} >
                        Добавить элемент
                    </motion.button>
                </div>
            ))}
        </div>
    );
};

export default ListManager;
