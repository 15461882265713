import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import styles from './CreateModal.module.css';
import { motion } from "framer-motion";
import BlockBody from '../BlockBody/BlockBody';
import ListManager from '../ListManager/ListManager';
import { uploadFile } from '../../http/userApi';
import FullScreenNotification from '../FullScreenNotification/FullScreenNotification';
import Loader from '../Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, fetchItems } from '../../reducers/itemsReducer';

function CreateModal({ openModal, setOpenModal }) {
    const [loading, setLoading] = useState(false);
    const [lists, setLists] = useState([]);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [error, setError] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [prevImage, setPrevImage] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        price: ''
    });

    const items = useSelector(state => state.items);
    const dispatch = useDispatch();

    BlockBody({ open: openModal });

    const handleUpload = async () => {

        setError(true);

        if (formData.title && formData.description && formData.price && selectedImage && lists) {
            const formDataToSend = new FormData();
            formDataToSend.append('title', formData.title);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('price', formData.price);
            formDataToSend.append('image', selectedImage);
            formDataToSend.append('options', JSON.stringify(lists));

            setLoading(true);

            const data = await uploadFile(formDataToSend);
            const newItem = {
                _id: data._id,
                description: formData.description,
                functions: lists,
                image: data.image || selectedImage.name,
                name: formData.title,
                price: formData.price,
                count: 10,
                type: "product",
            };

            dispatch(fetchItems({ ...items, items: [...items.items, newItem] }));
            setLoading(false);
            setNotificationVisible(true);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedImage(file);

            const reader = new FileReader();
            reader.onload = () => {
                setPrevImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <dialog className={styles.modal__wrapper} open={openModal}>
            <IoMdClose className={styles.close__btn} onClick={() => setOpenModal(false)} />
            <div className={styles.modal}>
                <h2>Создание</h2>

                <form className={styles.form}>
                    <div className={styles.field}>
                        <label className={styles.label}>Название:</label>
                        <input
                            className={`${styles.input} ${error && !formData.title ? styles.error__input : ''}`}
                            type="text"
                            placeholder='Название'
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                        />
                        {error && !formData.title && <p className={styles.error}>Заполните поле</p>}
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>Описание:</label>
                        <input
                            className={`${styles.input} ${error && !formData.description ? styles.error__input : ''}`}
                            type="text"
                            placeholder='Описание'
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                        />
                        {error && !formData.description && <p className={styles.error}>Заполните поле</p>}
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>Цена:</label>
                        <input
                            className={`${styles.input} ${error && !formData.price ? styles.error__input : ''}`}
                            type="number"
                            placeholder='Цена'
                            name="price"
                            value={formData.price}
                            onChange={handleInputChange}
                        />
                        {error && !formData.price && <p className={styles.error}>Заполните поле</p>}
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>Изменить фото:</label>
                        <label htmlFor="file-upload" className={`${styles.custom__file__upload} ${error && !selectedImage ? styles.error__input : ''}`}>
                            Выбрать файл
                        </label>
                        <input
                            id="file-upload"
                            onChange={handleImageChange}
                            className={styles.input}
                            type="file"
                            accept="image/*"
                        />
                        {prevImage && (
                            <picture className={styles.item__picture} >
                                <img className={styles.item__img} src={prevImage} alt="" />
                            </picture>
                        )}

                        {error && !selectedImage && <p className={styles.error}>Загрузите изображение</p>}
                    </div>

                    <ListManager lists={lists} setLists={setLists} />

                </form>

                <footer className={styles.footer}>
                    <motion.button type='button' whileTap={{ scale: 0.95 }} onClick={() => handleUpload()} className={styles.create__btn} >Создать</motion.button>
                </footer>

            </div>

            {notificationVisible && (
                <FullScreenNotification
                    message="Товар успешно создан!"
                    onConfirm={() => setOpenModal(false)}
                    confirmText="Хорошо"
                    onlyConfirm={true}
                    onCancel={() => setNotificationVisible(false)}
                />
            )}

            {loading &&
                <div className={styles.loader}>
                    <Loader />
                    <p className={styles.loader__text}>Идет загрузка...</p>
                </div>
            }
        </dialog>
    );
}

export default CreateModal;
