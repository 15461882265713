import React, { useState } from 'react';
import styles from './AdminCatalog.module.css';
import CatalogItems from '../../components/CatalogItem/CatalogItems';
import { useNavigate } from 'react-router-dom';
import CreateModal from '../../components/CreateModal/CreateModal';
import { motion } from "framer-motion";
import RelatedItems from '../../components/RelatedItems/RelatedItems';

function AdminCatalog() {
    const [openModal, setOpenModal] = useState(false);
    const [openRelated, setOpenRelated] = useState(false);
    const navigate = useNavigate();

    return (
        <section className={styles.catalog}>
            <h2 className={styles.title}>Изменение каталога</h2>

            <div className={styles.catalog__container}>
                <CatalogItems />
            </div>

            <footer className={styles.footer}>
                <div className={styles.footer__btns}>
                    <motion.button whileTap={{ scale: 0.95 }} className={styles.create__btn} onClick={() => setOpenRelated(true)}>
                        Сопутствующие товары
                    </motion.button>
                    <motion.button whileTap={{ scale: 0.95 }} className={styles.create__btn} onClick={() => setOpenModal(true)}>
                        Создать новый товар
                    </motion.button>
                </div>
                <motion.button whileTap={{ scale: 0.95 }} className={styles.footer__btn} onClick={() => navigate('/')}>
                    Назад
                </motion.button>
            </footer>

            {openModal && <CreateModal openModal={openModal} setOpenModal={setOpenModal} />}
            {openRelated && <RelatedItems openRelated={openRelated} setOpenRelated={setOpenRelated} />}
        </section>
    );
}

export default AdminCatalog;
