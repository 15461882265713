import React, { useEffect, useState } from 'react';
import styles from './ChangeInfo.module.css';
import { deleteInfo, fetchChangeInfo } from '../../http/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { v4 as uuidv4 } from 'uuid';
import ListManager from '../ListManager/ListManager';
import FullScreenNotification from '../FullScreenNotification/FullScreenNotification';
import { fetchItems, updateItem } from '../../reducers/itemsReducer';
import Loader from '../Loader/Loader';

function ChangeInfo({ item }) {

    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (item && item.functions) {
            const newLists = item.functions.map(func => ({
                id: uuidv4(),
                name: func.functionName,
                items: func.options.map(option => ({
                    id: uuidv4(),
                    name: option.name,
                    price: option.price
                }))
            }));

            setLists(newLists);
        }
    }, [item, setLists]);

    const [data, setData] = useState({
        name: item.name,
        description: item.description,
        price: item.price,
        image: null
    });

    const [error, setError] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);

    const user = useSelector(state => state.user);
    const items = useSelector(state => state.items);

    const handleFileChange = (e) => {
        const selectedImage = e.target.files[0];
        setData({ ...data, image: selectedImage });

        const imageURL = URL.createObjectURL(selectedImage);
        setPreviewImage(imageURL);
    };

    const handleChange = async () => {

        setError(true);

        if (data.name && data.description && data.price) {
            const formData = new FormData();

            formData.append('id', item._id);
            formData.append('user_id', user.id);
            formData.append('name', data.name);
            formData.append('description', data.description);
            formData.append('price', data.price);
            formData.append('image', data.image);
            formData.append('options', JSON.stringify(lists));

            setLoading(true);
            const newData = await fetchChangeInfo(formData);
            const newItem = {
                _id: item._id,
                description: data.description,
                functions: lists,
                image: newData.filename !== null && newData.filename,
                name: data.name,
                price: data.price,
                count: 10,
                type: "product",
            };
            dispatch(updateItem(newItem));
            setLoading(false);
            navigate('/');
        }
    };

    const handleDelete = async () => {
        const data = items?.items.filter(el => el._id !== item._id);
        dispatch(fetchItems({ items: data }));
        setLoading(true);
        await deleteInfo(user.id, item._id);
        setLoading(false);
        navigate('/');
    };

    return (
        <section className={styles.change__info}>
            <h3 className={styles.title}>Изменение элемента</h3>

            <form className={styles.admin__change}>

                <div className={styles.field}>
                    <label className={styles.label}>Изменить название:</label>
                    <input
                        value={data.name}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                        className={`${styles.input} ${error && !data.name ? styles.error__input : ''}`}
                        type="text"
                    />
                    {error && !data.name && <p className={styles.error}>Поле не может быть пустым</p>}
                </div>

                <div className={styles.field}>
                    <label className={styles.label}>Изменить описание:</label>
                    <input
                        value={data.description}
                        onChange={(e) => setData({ ...data, description: e.target.value })}
                        className={`${styles.input} ${error && !data.description ? styles.error__input : ''}`}
                        type="text"
                    />
                    {error && !data.description && <p className={styles.error}>Поле не может быть пустым</p>}
                </div>

                <div className={styles.field}>
                    <label className={styles.label}>Изменить цену:</label>
                    <input
                        value={data.price}
                        onChange={(e) => setData({ ...data, price: e.target.value })}
                        className={`${styles.input} ${error && !data.price ? styles.error__input : ''}`}
                        min={1}
                        type="number"
                    />
                    {error && !data.price && <p className={styles.error}>Поле не может быть пустым</p>}
                </div>

                <div className={styles.field}>
                    <label className={styles.label}>Изменить фото:</label>
                    <label htmlFor="file-upload" className={styles.custom__file__upload}>
                        Выбрать файл
                    </label>
                    <input
                        id="file-upload"
                        onChange={handleFileChange}
                        className={styles.input}
                        type="file"
                        accept="image/*"
                    />
                    {previewImage && (
                        <picture className={styles.item__picture} >
                            <img className={styles.item__img} src={previewImage} alt="" />
                        </picture>
                    )}
                </div>

                <ListManager lists={lists} setLists={setLists} change={true} />

                <div className={styles.buttons}>

                    <motion.button type='button'
                        className={styles.delete__btn}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => setNotificationVisible(true)} >
                        Удалить
                    </motion.button>

                    <motion.button type='button'
                        className={styles.btn}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => handleChange()}>
                        Сохранить
                    </motion.button>
                </div>
            </form>

            {notificationVisible && (
                <FullScreenNotification
                    message="Вы действительно хотите удалить этот элемент?"
                    onConfirm={() => handleDelete()}
                    confirmText="Да"
                    cancelText="Нет"
                    onCancel={() => setNotificationVisible(false)}
                />
            )}

            {loading &&
                <div className={styles.loader}>
                    <Loader />
                    <p className={styles.loader__text}>Идет загрузка...</p>
                </div>
            }
        </section>
    );
}

export default ChangeInfo;
