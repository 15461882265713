import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import styles from './BuyAll.module.css';

function BuyAll({ item, selectedItems, setSelectedItems }) {
    const [openItems, setOpenItems] = useState([]);

    useEffect(() => {
        setOpenItems(item.functions.map(el => ({ name: el.functionName, open: true })));
        setSelectedItems(item.functions.map(el => ({ name: el.functionName, option: el.options[0] })));
    }, [item])

    const toggleFunction = (name) => {
        setOpenItems(openItems.map(el => el.name === name ? { ...el, open: !el.open } : el));
    };

    const handleSelect = (functionItem, option) => {
        const filteredItems = selectedItems.filter(el => el.name !== functionItem.functionName);

        const isSelected = selectedItems.find(el => el.name === functionItem.functionName && el.option === option);
        if (!isSelected) {
            setSelectedItems([...filteredItems, { name: functionItem.functionName, option }]);
        } else {
            setSelectedItems(filteredItems);
        }
    }

    return (
        <section className={styles.buy__all}>
            <ul className={styles.buy__all__info}>
                {item?.functions.map((functionItem, index) => (
                    <li key={index} className={styles.buy__all__info__list}>
                        <div
                            className={styles.buy__all__info__item}
                            onClick={() => toggleFunction(functionItem.functionName)}
                        >
                            <p>{functionItem.functionName}</p>
                            <div className={styles.buy__all__info__item__arrow}>
                                {openItems.find(el => el.name === functionItem.functionName && el.open) ?
                                    <MdKeyboardArrowUp className={styles.buy__all__info__item__icon} /> :
                                    <MdKeyboardArrowDown className={styles.buy__all__info__item__icon} />}
                            </div>
                        </div>

                        <ul
                            className={`${styles.buy__all__info__item__options} ${openItems.find(el => el.name === functionItem.functionName && el.open) && styles.buy__all__info__item__options__open}`}>
                            {functionItem?.options.map((option, optionIndex) => {

                                const isSelected = selectedItems.find(el => el.name === functionItem.functionName && el.option === option);
                                const isNoneSelected = !selectedItems.find(el => el.name === functionItem.functionName);
                                const highlightFirstByDefault = isNoneSelected && optionIndex === 0;

                                const optionClass = `${styles.buy__all__info__item__option} ${isSelected || highlightFirstByDefault ? styles.selectedOption : ''}`;

                                return (
                                    <li onClick={() => handleSelect(functionItem, option)} className={optionClass} key={optionIndex}>
                                        {option.name} {option.price} руб.
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default BuyAll;
